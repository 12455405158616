
<template>
	<div class="page">
		<div class="service_banner relative">
			<img class="img" :src="bannerObj.imageUrl || require('../assets/static/xmzy_bg.png')"/>
			<div class="title">
				<div class="fontsize48 colorwhite">{{bannerObj.title || ""}}</div>
				<div class="desc fontsize18 colorwhite">{{bannerObj.subtitle || ""}}</div>
			</div>
		</div>
		<!-- 条件查询 -->
		<div class="huaxue_prodockitem_box">
			<div class="formitem_box w1239">
				<el-form ref="formObj" :model="formObj" label-width="120px">
					<el-form-item label="需求类型：">
					    <div class="xqlx_list fontsize14">
							<!-- <div class="item hover">不限</div> -->
							<div class="item" @click="handledemandType(item.id)" :class="{'hover':formObj.demandTypeId==item.id}" v-for="(item,index) in demandList" :key="index">{{item.name}}</div>
						</div>
					</el-form-item>
					<el-form-item label="截止时间：">
					    <el-col :span="5">
					      <el-date-picker type="date" placeholder="选择开始日期" v-model="formObj.startDate" style="width: 100%;" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
					    </el-col>
					    <el-col class="line" :span="1" style="text-align: center;">-</el-col>
					    <el-col :span="5">
					      <el-date-picker @change="handleComfirm" type="date" placeholder="选择结束日期" v-model="formObj.endDate" style="width: 100%;" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
					    </el-col>
					</el-form-item>
					<el-form-item label="所在地：">
					    <el-select v-model="formObj.city" placeholder="请选择" @change="handleComfirm">
						    <el-option label="不限" :value="null"></el-option>
					        <el-option v-for="(item, index) in addressList"
					        :key="index" :label="item.city" 
					        :value="item.city">
					        <span style="float: left">{{ item.province }}</span>
					        <span style="float: right;">{{ item.city }}</span>
					        </el-option>
					    </el-select>
					</el-form-item>
					<!-- <el-form-item label="防疫需求：">
					    <el-select v-model="formObj.epidemicPrevention" placeholder="请选择">
					      <el-option label="区域一" value="shanghai"></el-option>
					      <el-option label="区域二" value="beijing"></el-option>
					    </el-select>
					</el-form-item> -->
					  
				</el-form>
			</div>
		</div>
		<!-- 内容 -->
		<div class="prodockitem_search_box w1239">
			<div class="search_box">
				<div class="inputdiv">
					<input v-model="formObj.title" class="fontsize16" placeholder="请输入搜索内容"/>
				</div>
				<div class="searchbtn fontsize18" @click="handleComfirm">搜索</div>
			</div>
		</div>
		<div class="prodockitem_datalist_box w1239">
			<div class="itemobj pointer" v-for="(item,index) in dataList" :key="index" @click="$util.routerPath('/proDockDetail',{uid:item.id,typeName:typeName})">
				<div class="left">
					<div class="titlename fontsize24 clamp">{{item.title}}</div>
					<div class="descdiv fontsize16">
						<div class="text">行业分类：{{item.industryType}}</div>
						<div class="text">需求类型：{{item.demandType}}</div>
					</div>
					<div class="descdiv fontsize16">
						<div class="text">截止时间：{{item.endDate}}</div>
						<div class="text">所在地：{{item.province}}{{item.city}}</div>
					</div>
				</div>
				<div class="right">
					<div class="tip fontsize20">简介</div>
					<div class="text clamp2 fontsize16">{{item.body}}</div>
				</div>
			</div>
		</div>
		<div class="emptyText fontsize16" v-if="dataList.length<=0">暂无相关记录~</div>
		<div class="block30"></div>
		<div class="w1239 phonenone" style="text-align:right">
		    <el-pagination
		          @current-change="handleCurrentChange"
		          :current-page.sync="currentPage"
		          :page-size="pageSize"
		          layout="prev, pager, next, jumper"
		          :total="total">
		    </el-pagination>
		</div>
		<div class="w1239 pcnone" style="text-align:right">
		    <el-pagination
			      small
		          @current-change="handleCurrentChange"
		          :current-page.sync="currentPage"
		          :page-size="pageSize"
		          layout="prev, pager, next"
		          :total="total">
		    </el-pagination>
		</div>
		
		<div class="block58"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			typeName:"技术需求",
			dataList:[],
			currentPage:1,
			pageSize:10,
			total:0,
			formObj:{
				title:"",
				successFlag:1,//项目类型 0项目成果 1技术需求
				epidemicPrevention:"",
				demandTypeId:null,//需求分类，null是查询全部
				startDate:"",//截止时间开始
				endDate:"",//截止时间结束
				city:"",//所在市
			},
			industryList:[],
			demandList:[
				{
					id:null,
					name:"不限"
				}
			],
			addressList:[],
			bannerObj:{}
		};
	},
	mounted: function() {
		//头图
		this.getBannerFuc(17).then(bannerObj => {
			//console.log(bannerObj.imageUrl)
			if(bannerObj&&bannerObj.imageUrl){
				this.bannerObj = bannerObj
			}
		});
		//行业分类
		//this.getindustryList()
		//需求类型
		this.getdemandList()
		//区域地址
		this.getaddressList()
		//获取(项目资源)
		this.getProjectResource()

	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//行业分类
		getindustryList(){
			var _this = this
			var params = {currentPage: 1,pageSize: 88}
			this.$http.post('projectResource/project-resource-industry/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.industryList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//需求类型
		getdemandList() {
			var _this = this
			var params = {currentPage: 1,pageSize: 88}
			this.$http.post('manager/project-resource-demand-type/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.demandList = _this.demandList.concat(records)
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//区域地址
		getaddressList() {
			var _this = this
			var params = {currentPage: 1,pageSize: 88}
			this.$http.post('mamager/project-resource-address/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.addressList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//需求点击
		handledemandType(type){
			this.formObj.demandTypeId = type
			this.handleComfirm()
		},
		//查询确认
		handleComfirm(){
			this.currentPage = 1
			this.getProjectResource()
		},
		//跳转页面
		handleCurrentChange(currentPage){
			//console.log(currentPage)
			this.currentPage = currentPage
			this.getProjectResource()
		},
		//获取(项目资源)
		getProjectResource() {
			var _this = this;
			var params = this.formObj 
			params["currentPage"] = this.currentPage
			params["pageSize"] = this.pageSize
			this.$http.post('frontEnd/ProjectResource/getProjectResource', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					records.forEach((item, index) => {
						//console.log(item)
						item["body"] = _this.$util.filtersText(item.resourceBody,58)
						if(item.imagesList&&item.imagesList!='[]'){
							try{
								item["imgUrl"] = JSON.parse(item.imagesList)[0]
							}catch(e){
								//TODO handle the exception
							}
						}
					})
					_this.dataList = records
					_this.total = res.data.total
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
